export enum TextKey {
  HEADER_BEFORE_LOGO = "header-before-logo",
  HEADER_AFTER_LOGO = "header-after-logo",
  HEADER_CTA = "header-cta",
  HOMEPAGE_CONTENT = "homepage-content",
  SHARE_HEADER_BEFORE_LOGO = "share-header-before-logo",
  SHARE_HEADER_AFTER_LOGO = "share-header-after-logo",
  SHARE_FORM_TITLE = "share-form-title",
  FORM_FIRSTNAME = "form-firstname",
  FORM_EMAIL = "form-email",
  FORM_POSTAL = "form-postal",
  FORM_OPTIN_TITLE = "form-optin-title",
  FORM_OPTIN_DESCRIPTION = "form-optin-description",
  FORM_OPTIN_CTA_DETAILS = "form-optin-cta-details",
  FORM_OPTIN_CTA_YES = "form-optin-cta-yes",
  FORM_OPTIN_CTA_NO = "form-optin-cta-no",
  FORM_OPTIN_DISCLAIMER = "form-optin-disclaimer",
  FORM_SUBMIT = "form-submit",
  PETITION_TITLE = "petition-title",
  PETITION_SIGNED_TITLE = "petition-signed-title",
  PETITION_SIGNED_USER = "petition-signed-user",
  PETITION_SHARED_USER = "petition-shared-user",
  LEGALS = "legals",
  LEGALS_CTA = "legals-cta",
  BACK_TO_HOMEPAGE = "back-to-homepage",
  NOT_FOUND = "404-not-found",
  SHARE_CTA_FACEBOOK = "share-cta-facebook",
  SHARE_CTA_WHATSAPP = "share-cta-whatsapp",
  SHARE_CTA_LINK = "share-cta-link",
  SHARE_CTA_MESSENGER = "share-cta-messenger",
  SHARE_CTA_TWITTER = "share-cta-twitter",
  CHECK_EMAIL_TEXT = "check-email-text",
  CHECK_EMAIL_CTA = "check-email-cta",
}
